<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <h3>{{ `${supplier.company_name}` }} - {{ $t("COMMON.CONTACTS") }}</h3>
      <base-button
        class="elite-button add"
        icon
        size="sm"
        @click="addSupplierContact()"
        v-if="$currentUserCan($permissions.PERM_CREATE_CONTACTS)"
      >
        <span class="btn-inner--icon">
          <i class="far fa-plus-circle"></i>
        </span>
        <span class="btn-inner--text">{{ $t("COMMON.ADD_CONTACT") }}</span>
      </base-button>
    </div>
    <supplier-view-contacts-add-contact-modal
      :supplier="supplier"
      :showModal="showContactAddModal"
      @onCloseModal="onCloseModal"
    />
    <contact-list-table
      :filterOrganization="supplier.organization.id"
      :filterContactableType="`App\\Models\\Supplier`"
      :filterContactableId="supplier.id"
      :key="rerenderKey"
    />
  </div>
</template>

<script>
import ContactListTable from "../../../CrmModule/ContactManagement/partials/ContactListTable.vue";
import SupplierViewContactsAddContactModal from "./SupplierViewContactsAddContactModal.vue";

export default {
  name: "supplier-view-contacts",

  components: { ContactListTable, SupplierViewContactsAddContactModal },

  props: ["supplier"],

  data() {
    return {
      showContactAddModal: false,
      rerenderKey: 0,
    };
  },

  computed: {},

  created() {},

  methods: {
    addSupplierContact() {
      this.showContactAddModal = true;
    },

    onCloseModal() {
      this.showContactAddModal = false;
      this.$emit("supplierUpdated", true);
      this.rerenderKey++;
    },
  },

  mounted() {},

  watch: {
    supplier(supplier) {},
  },
};
</script>
